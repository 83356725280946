<template>
    <div>
        <div class="modal-backdrop fade show"></div>
        <!-- Custom field modal -->
        <div class="modal fade custom-fields show" id="new-pipeline-template" style="display: block;">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <form action="" @submit.prevent="">
                    <div class="modal-content" v-on-click-away="close">
                        <div class="modal-header">
                            <h4 class="modal-title text-uppercase">{{ $t("Update Attachment") }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                    @click.prevent="close()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body" style="min-height: 300px;">
                            <div class="form-row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>{{$t('Comment')}}</label>

                                        <textarea ref="attachment_name" v-model="form.comment" rows="4"
                                                :placeholder="$t('Add comment')"
                                                maxlength="200"
                                                class="label__full--primary form-control">

                                      </textarea>
                                        <small class="float-right text-muted">{{ $t('Max 200') }}</small>
                                        <div class="error-message" v-text="errors.comment"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer d-flex justify-content-between">
                            <button class="button semi-button-info" data-dismiss="modal" @click.prevent="close">
                                {{ $t('back') }}
                            </button>
                            <button class="button primary-button" @click="updateAttachment()">{{ $t('Update') }}</button>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import client from "../../../app/api/Client";
import {debounce} from "lodash";


export default {
    name: 'SearchJobTemplate',
    props: ['attachment', 'applicantId'],
    data() {
        return {
            isLoading: false,
            form: {
                name: '',
                comment: '',
            },
            errors: {
                name: '',
                comment: '',
            }
        }

    },
    computed: {
    },
    methods: {
        close() {
            this.$emit('closeAttachmentTemplateModal');
        },
       async updateAttachment() {
            try {
                let {data: {data, message}} = await client().post(`job/applicant/${this.applicantId}/attachment/${this.attachment.id}/update`,this.form );
                this.$toast.success(message);
                this.close();
            } catch (err) {
                    this.$toast.error(err.response.data.message);
            }

       }
    },
    async created() {
    },
    mounted() {
        this.$refs.attachment_name.focus();
        this.form.name = this.attachment.name;
        this.form.comment = this.attachment.comment;
    }
};
</script>

<style scoped>
.table-modal .table-cell {
    padding: 10px !important;
    background: #f8f8fd !important;
}

.pinter-select {
    cursor: pointer;
}

@media (max-width: 767.98px) {
    .table-modal .table__row {
        margin-bottom: 10px !important;
    }

    .table-modal .table__row .table-cell {
        flex: 0 0 50%;
    }
}

</style>>